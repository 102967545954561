import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"

const Services = () => {
	const query = useStaticQuery(graphql`
		query {
			allServicesJson {
				edges {
					node {
						title
						desc
					}
				}
			}
			allStrapiPosts(filter: {categories: {elemMatch: {name: {eq: "services"}}}}, limit: 6, sort: {fields: created_at, order: DESC}){
				edges{
					node{
						id
						post_title
						post_excerpt
						icon
					}
				}
			}
		}
	`)

	
	let serviceHeader = [];
	query.allServicesJson.edges.forEach((item)=>{
		serviceHeader.push(item.node);
	});
	serviceHeader = serviceHeader[0];
	const serviceContent = [];
	query.allStrapiPosts.edges.forEach((item)=>{
		serviceContent.push(item.node);
	})
	let services = serviceContent;
	return(
		<section id="services" className="section-padding bg-gray">
			<div className="container">
				<div className="section-header text-center">
					<h2 className="section-title wow fadeInDown">{serviceHeader.title}</h2>
					<p dangerouslySetInnerHTML={{__html: serviceHeader.desc}}/>
				</div>
				<div className="row">
					{
						services.map((item, index)=>{
							return (
								<div className="col-md-6 col-lg-4 col-xs-12" key={`key_${index}`}>
									<div className="services-item wow fadeInRight">
										<div className="icon">
											<i className={item.icon}></i>
										</div>
										<div className="services-content">
											<h3><Link to={`/${item.id}`}>{item.post_title}</Link></h3>
											<p>{item.post_excerpt}</p>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		</section>
	)
}

export default Services