import React from 'react';
import Link from 'gatsby-link'
import { useStaticQuery, graphql } from "gatsby"

const Feature = () => {
	const query = useStaticQuery(graphql`
		query {
			allFeaturesJson {
				edges {
	      			node {
	        			title
	        			desc
	      			}
	    		}
			}
			allStrapiFeatures(sort: {fields: created_at, order: ASC}) {
			    edges {
			      node {
			        id
			        title
			        icon
			        description
			      }
			    }
			}
		}
	`)
	let featuresHeader = [];
	query.allFeaturesJson.edges.forEach(({node})=>{
		featuresHeader.push(node);
	})
	featuresHeader = featuresHeader[0];
	let featureContent = [];
	query.allStrapiFeatures.edges.forEach(({node})=>{
		featureContent.push(node);
	})
	return (
		<div id="feature">
			<div className="container-fluid">
		    	<div className="row">
		      		<div className="col-lg-6 col-md-12 col-sm-12">
		        		<div className="text-wrapper">
		          			<div>
					            <h2 className="title-hl wow fadeInLeft" dangerouslySetInnerHTML={{ __html: featuresHeader.title }}/>
					            <p className="mb-4">{featuresHeader.desc}</p>
					            <Link to="/about-us" className="btn btn-common">More About Us</Link>
		          			</div>
		        		</div>
		      		</div>
		      		<div className="col-lg-6 col-md-12 col-sm-12 padding-none feature-bg">
		        		<div className="feature-thumb">
		          			{
		          				featureContent.map((item, index)=>{
		          					return (
					          			<div className="feature-item wow fadeInDown" key={`key_${index}`}>
					            			<div className="icon">
												<i className={item.icon}></i>
					            			</div>
					            			<div className="feature-content">
					              				<h3>{item.title}</h3>
					              				<p>{item.description}</p>
					            			</div>
					          			</div>
		          					)
		          				})
		          			}
		        		</div>
		      		</div>
		    	</div>
		  	</div>
		</div>
	)
}

export default Feature