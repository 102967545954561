import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import SubHeader from '../components/subheader'
import FeatureSection from '../components/feature';
import ServiceSection from '../components/services';

const Service = () => {
    return (
        <Layout>
            <SEO title="Service" />
            <SubHeader title="Service"/>
            <FeatureSection/>
            <ServiceSection/>
        </Layout>
    )
}

export default Service